import { UrlParamsReplace } from './index';

export const TAX_OPTIMISER_QUESTIONNAIRES = () => {
    return UrlParamsReplace('/tax-optimiser-questionnaires');
};

export const GET_TAX_OPTIMISER_RESULTS = (entityId) => {
    return UrlParamsReplace('/tax-optimiser-results?entityId=:entityId', {
        entityId,
    });
};

export const TAX_OPTIMISER_MEETINGS = () => {
    return UrlParamsReplace('/tax-optimiser-meetings');
};

export const GET_FEATURE_USER = (entityId, featureId) => {
    return UrlParamsReplace('/feature-users/:entityId/feature/:featureId', {
        entityId,
        featureId,
    });
};

export const UPDATE_TAX_OPTIMISER_RESULTS = (id) => {
    return UrlParamsReplace('/tax-optimiser-results/:id', {
        id
    });
};
