import { investmentFallbackStats } from '@helpers/constants';
import { theme } from 'tailwind.config';

export const getNriSignupPageStats = (data) => {
    if (data) {
        return [
            {
                suffix: 'rupeeSymbol',
                amount: data?.entity?.investedAmount
                    ? data?.entity?.investedAmount
                    : investmentFallbackStats.investedAmount,
                tagline: 'Crores',
                title: 'Total Investment',
                color: theme?.colors?.secondary?.[100],
                category: '',
                duration: 1.3,
                countSuffix: '+'
            },
            {
                suffix: 'rupeeSymbol',
                amount: data?.entity?.repaidAmount
                    ? data?.entity?.repaidAmount
                    : investmentFallbackStats.repaidAmount,
                tagline: 'Crores',
                title: 'Repayments Made',
                color: theme?.colors?.secondary?.[100],
                category: '',
                delay: 0.6,
                duration: 1.2,
                countSuffix: '+'
            },
            {
                suffix: 'percentageSymbol',
                amount: data?.entity?.averageReturns
                    ? data?.entity?.averageReturns
                    : investmentFallbackStats.averageReturns,
                tagline: '',
                title: 'Avg Returns Earned',
                color: theme?.colors?.primary?.[30],
                category: 'Per Annum',
                categoryShortName: 'p.a',
                decimalPlaces: 1,
                duration: 0.5,
                delay: 1.5,
            },
        ];
    }

    return [];
};

export const invoiceProductInfoForNriSignup = {
    title: 'Our Invoice Discounting Products tailored to your diverse needs',
    brands: [
        {
            id: 'altWings',
            logo: '/images/brands/altWings.svg',
            title: 'Soar with high returns',
            bgColor: 'bg-altWings-bg-50',
            description: (
                <>
                    Invest in established and upcoming companies{' '}
                    <span className="p5-semibold">
                        across varied industries and sectors
                    </span>{' '}
                    . The investment is Unsecured.
                </>
            ),
            topLevelDetails: [
                {
                    label: 'IRR',
                    value: '12%-14%',
                },
                {
                    label: 'Internal Risk Rating',
                    value: 'Very high',
                },
            ],
        },
        {
            id: 'altBlu',
            logo: '/images/brands/altBlu.svg',
            title: 'Unlock growth with established companies',
            bgColor: 'bg-altBlu-bg-50',
            description: (
                <>
                    Invest in invoices raised by{' '}
                    <span className="p5-semibold">
                        blue chip or established companies
                    </span>{' '}
                    and all repayments are made via an altGraaf
                    controlled escrow account. 
                </>
            ),
            topLevelDetails: [
                {
                    label: 'IRR',
                    value: '11%-12.5%',
                },
                {
                    label: 'Internal Risk Rating',
                    value: 'Medium',
                },
            ],
        },
        {
            id: 'altArmour',
            logo: '/images/brands/altArmour.svg',
            title: 'Balance risk with insurance protection',
            bgColor: ' bg-altArmour-bg-50',
            description: (
                <>
                    Your investment is{' '}
                    <span className="p5-semibold">
                        secured by Trade Credit Insurance
                    </span>{' '}
                    and all repayments are made via altGraaf controlled
                    escrow account.
                </>
            ),
            topLevelDetails: [
                {
                    label: 'IRR',
                    value: '11%-12%',
                },
                {
                    label: 'Internal Risk Rating',
                    value: 'Low',
                },
            ],
        },
        {
            id: 'altSmart',
            logo: '/images/brands/altSmart.svg',
            title: 'Invest confidently with a bank guarantee',
            bgColor: 'bg-altSmart-bg-50',
            description: (
                <>
                    Your investment is{' '}
                    <span className="p5-semibold">
                        100% secured by Bank Guarantee (BG)
                    </span>{' '}
                    and all repayments are made via altGraaf controlled
                    escrow account.
                </>
            ),
            topLevelDetails: [
                {
                    label: 'IRR',
                    value: '10%-11%',
                },
                {
                    label: 'Internal Risk Rating',
                    value: 'Very Low',
                },
            ],
        },
    ],
}

export const signUpData = [
    {
        title: 'Diversify your Investments',
        description:
            'We offer you the opportunity to diversify your investments across varied sectors balancing risk while maximizing growth.',
        icon: '/images/homepage/diversify-icon.svg',
        mobileText: 'Diversify your Investments',
    },
    {
        title: 'Curated, risk-assessed opportunities designed for retail investors',
        description:
            'We offer you the opportunity to diversify your investments across varied sectors balancing risk while maximizing growth.',
        icon: '/images/homepage/diversify-icon.svg',
        className: 'inline-flex md:hidden',
    },
    {
        title: 'Earn Fixed and Predictable Returns',
        description:
            'Predictable returns help you plan your financial goals better. Earn returns ranging from 10% to 15%.',
        icon: '/images/homepage/predictable-returns.svg',
        mobileText: 'Earn returns ranging from 10% to 15% ',
    },
    {
        title: 'Choose across Flexible Tenures',
        description:
            'Investment opportunities on our platform have tenures starting from just 30 days.',
        icon: '/images/homepage/flexible-tenure.svg',
        mobileText: 'Tenure starting from just 30 days',
    },
];