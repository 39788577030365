import { taxOptimiserFeatureId } from '@components/TaxOptimiser/helpers/constants';
import { isValidResponseEntity } from '@helpers/utils';
import useLocalStorage from '@hooks/useLocalStorage';
import { getAuth } from '@services/identity.service';
import { getFeatureUserByFeatureIdAndEntityId } from '@services/tax-optimiser.service';
import { useEffect } from 'react';

const useTaxOptimisationEligibility = () => {
    const taxOptimiserEligibilityKey = 'isEligibleForTaxOptimiser';
    const [isEligibleForTaxOptimiser, setIsEligibleForTaxOptimiser] =
        useLocalStorage(taxOptimiserEligibilityKey, null);
    const auth = getAuth();

    const fetcher = async (id) => {
        if (!auth && !id) return false;
        try {
            const featureId = taxOptimiserFeatureId;
            const userEligibilityResponse =
                await getFeatureUserByFeatureIdAndEntityId(id || auth.id, featureId);
            if (isValidResponseEntity(userEligibilityResponse)) {
                return userEligibilityResponse.entity.isUserEligibleForFeature;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    const checkEligibility = async (id = null) => {
        if (!auth && !id) return null;
        try {
            if (isEligibleForTaxOptimiser === null || id) {
                const response = await fetcher(id);
                const isEligible = !!response;
                setIsEligibleForTaxOptimiser(isEligible);
            }
        } catch (error) {
            setIsEligibleForTaxOptimiser(false);
        }
    };

    useEffect(() => {
        checkEligibility();
    }, []);

    return { isEligibleForTaxOptimiser, checkEligibility };
};

export default useTaxOptimisationEligibility;
