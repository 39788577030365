import React, { useEffect, useState } from 'react';
import { Image, LazyImage } from '@atoms/index';
import { Text } from '@ui/atoms';
import texts from '@components/Referral/en.json';

const RefferalLoginData = ({
    isFestiveSession = false,
    isNriVisitor,
    isSignUp,
}) => {
    const [isScrolled, setIsScrolled] = useState(false);

    const data = [
        {
            image: '/images/iocn-3.svg',
            title: texts.RefferalLoginDiversifyYourInvestments,
            description: texts.RefferalLoginDiversifyYourInvestmentsDesc,
        },
        {
            image: '/images/iocn-1.svg',
            title: texts.RefferalLoginEarnFixedAndPredictableReturns,
            description: texts.RefferalLoginEarnFixedAndPredictableReturnsDesc,
        },
        {
            image: '/images/iocn-2.svg',
            title: texts.RefferalLoginChooseAcrossFlexibleTenures,
            description: texts.RefferalLoginChooseAcrossFlexibleTenuresDesc,
        },
    ];

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className={`pt-0 ${
                isFestiveSession ? 'xl:pt-8' : ''
            } h-min-fit px-2 relative md:px-12 md:pl-6 md:pr-0 xl:pl-14 xl:pr-12 w-full flex-col md:flex md:w-[60%] xl:w-[56%] self-start xl:!mb-10 ${
                isNriVisitor
                    ? `bg-nri-signup md:bg-none mb-6 md:mb-0 pt-12 md:pt-0 md:min-h-full ${
                          isScrolled ? 'min-h-0' : 'min-h-[100svh]'
                      }`
                    : ''
            } `}
        >
            {/* Logo Section */}
            {!isFestiveSession ? (
                <div className="hidden md:block mb-8 xl:mb-10">
                    <Image alt="logo" src="/images/logo.svg" />
                </div>
            ) : null}

            {isNriVisitor ? (
                <div className="md:hidden flex justify-center">
                    <Image src="/images/logo.svg" className="h-6" />
                </div>
            ) : null}

            <div className={`${isNriVisitor ? 'md:pr-4' : 'pr-4'}`}>
                {isFestiveSession ? (
                    <>
                        <div className="h5-regular md:h3-regular text-gray-900 block md:hidden mt-4 mb-5 text-center w-full">
                            {texts.ExploreTheWorldOf}{' '}
                            <span className="h5-semibold md:h3-semibold text-primary-500 inline md:hidden mb-6 text-center">
                                {texts.AlternativeFixedIncomeInvestments}
                            </span>
                        </div>
                    </>
                ) : null}
                {/* Heading */}
                {isNriVisitor ? (
                    <div className="flex flex-col items-center md:items-start">
                        <Text
                            content={texts.ExtraordinaryInvestments}
                            className="p2-regular md:h4-regular lg:h3-regular text-primary-500 mt-10 md:mt-6 lg:mt-12 text-center md:text-left"
                            isInnerHtml={true}
                        />
                        <Text
                            content={texts.ExceptionalReturns}
                            className="p2-regular md:h4-regular lg:h3-regular text-primary-500 text-center md:text-left"
                            isInnerHtml={true}
                        />
                        <Text
                            content={texts.YourGatewayHighYieldInvestments}
                            className="p5-regular md:p4-regular text-gray-900 mt-2 text-center md:text-left"
                        />
                        <div className="max-w-[448px] mt-8 md:mt-5 mb-10 md:mb-0 text-center">
                            <Image
                                src={'/images/signup/india-gate.webp'}
                                className="w-full h-full"
                                alt={`india-gate`}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <Text
                            content={`${texts.ExploreTheWorldOf} `}
                            className="h5-regular xl:h3-regular text-gray-900 md:inline hidden"
                            htmlTag='h2'
                        />
                        <Text
                            content={texts.AlternativeFixedIncomeInvestments}
                            className="h5-semibold xl:h3-semibold text-primary-500 hidden md:inline"
                             htmlTag='h2'
                        />
                        <Text
                            content={
                                texts.AlternativeFixedIncomeInvestmentsDesc
                            }
                            className={`text-gray-900 mt-2 p4-regular md:block mx-2 md:mx-0 ${
                                isFestiveSession || isSignUp
                                    ? 'text-center md:text-left'
                                    : 'text-left'
                            }`}
                        />
                        <div className=" flex flex-col md:gap-6 justify-center content-center pt-6 md:pt-0 md:mt-10">
                            {/* Advantages of altGraaf */}

                            {data.map((item, index) => (
                                <div
                                    key={index}
                                    className="p-2 md:p-0 flex rounded-lg mb-4 md:mb-2 mr-4 md:mr-8 gap-4 md:gap-2"
                                >
                                    <div className="w-16 md:mr-4">
                                        <LazyImage
                                            src={item.image}
                                            className="w-16 h-16 relative"
                                            alt={`icon-${index}`}
                                        />
                                    </div>
                                    <div className="w-3/4 md:w-full pl-0 xl:pl-0 flex-1">
                                        <h5 className="p5-semibold xl:p4-semibold mb-1 text-gray-900">
                                            {item.title}
                                        </h5>
                                        <p className="p6-regular md:p5-regular text-gray-600 xl:mr-10">
                                            {item.description}
                                            <span
                                                id={'signUpHome' + index}
                                            ></span>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default RefferalLoginData;
