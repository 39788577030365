import { Image } from '@atoms/index';
import LoginModalData from '@components/Auth/LoginModal/LoginModalData';
import VerifyOtpModal from '@components/Auth/VerifyOtpModal';
import VerifyPhoneAndEmailModal from '@components/Auth/VerifyPhoneAndEmailModal';
import useIntersection from 'hooks/useIntersection';
import { useEffect, useRef, useState } from 'react';
import { createSession } from '@services/session.service';
import MessageModal from '@molecules/MessageModal';
import { useRouter } from 'next/router';
import { useInvestorContext } from '@context/InvestorContextProvider';
import { otpContextEnum } from '@ui/helpers/enum';
import { triggerCustomEvent } from '@helpers/miscelleanous';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';
import texts from '@components/Referral/en.json';
import {
    logEvent,
    setUserData,
    moengageEventConstants,
    setUserAttribute,
    moengageEventArgsConstants, moengageAttributesConstants
} from "@services/moengage";

const WithOpportunityPage = ({
    referralCode,
    showEmail,
    referralEntity,
    opportunityDetails,
    login,
    stickyBtnVisible,
    setStickyBtnVisible,
}) => {
    const [showEmailInput, setShowEmailInput] = useState(showEmail);
    const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);
    const [userInput, setUserInput] = useState({});
    const [loading, setLoading] = useState(false);
    const [multipleLogin, setMultipleLogin] = useState(false);
    const [isSessionCreationError, setIsSessionCreationError] = useState(false);
    const router = useRouter();
    const { setMenus } = useInvestorContext();
    const mobileRef = useRef(null);
    const emailRef = useRef(null);
    const [otpModes, setOtpModes] = useState([]);
    const [isModalInLoadingState, setIsModalInLoadingState] = useState(false);

    const editMobileNumber = () => {
        setShowVerifyOtpModal(false);
        mobileRef.current?.numberInputRef?.focus();
    };

    const editEmail = () => {
        setShowVerifyOtpModal(false);
        emailRef.current?.focus();
    };

    const onBack = () => {
        showVerifyOtpModal(false);
        setShowVerifyOtpModal(false);
    };

    const onLoginModalClose = () => {
        setShowVerifyOtpModal(false);
        setLoading(false);
    };

    const onOtpVerificationSuccess = async ({ otpIdList }) => {
        const gclid = router?.query?.gclid;
        const response = await createSession({
            mobile: userInput.mobile,
            otpIdList,
            referralEntity,
            userDetails: { ...userInput, countryCode: userInput.countryCodeWithPrefix },
            gclid,
            router,
            setMenus,
            opportunityId
        });
        if (response.status) {
            await setUserData({auth: response?.entity});
            logEvent({
                eventName: moengageEventConstants.SIGNUP_SUCCESS,
                parameters: {
                    [moengageEventArgsConstants.INVESTMENT_TIME_FRAME]: userInput?.investmentTimeFrame
                }
            });
            setUserAttribute({
                attributeName: moengageAttributesConstants.WHATSAPP_OPT_IN,
                attributeValue: userInput?.whatsappOptIn,
            });
            triggerCustomEvent(gaEventNameEnum.SIGNUP_VERIFY_OTP, { mobile: userInput.mobile });
        }
        setIsSessionCreationError(!response.status);
    }

    const {
        opportunityId,
        opportunityLogo,
        opportunityName,
        opportunityTitle,
    } = opportunityDetails;
    const [showsignup, setshowsignUp] = useState(!login);
    const [showLogin, setShowLogin] = useState(login);
    const ref = useRef();
    const inViewport = useIntersection(ref, '-100px');

    useEffect(() => {
        if (!inViewport) {
            setStickyBtnVisible({ ...stickyBtnVisible, display: 'block' });
        } else {
            setStickyBtnVisible({ ...stickyBtnVisible, display: 'hidden' });
        }
    }, [inViewport]);

    return (
        <>
            <div
                id="signUpHome"
                className="lg:h-125vh xl:h-100vh  2xl:h-95vh signup-opportunity-page-bg w-full bg-primary-400   2xl:bg-center bg-no-repeat bg-cover"
            >
                <div className="lg:h-125vh  xl:h-100vh 2xl:h-95vh 2xl:container 2xl:mx-auto flex flex-col-reverse py-8 md:py-0 md:flex-row">
                    {/* Infographic */}
                    <div className=" w-full md:w-1/2 md:items-center flex flex-col justify-center align-center">
                        <div className=" m-4 md:m-10 justify-center align-center flex  flex-col">
                            {/* Logos Section */}
                            <div className="  flex flex-row justify-center mb-4">
                                <div className="bg-basicWhite rounded-lg flex justify-center items-center w-16 h-16 md:w-20 md:h-20  my-6 2xl:w-28 2xl:h-28 mr-10 ">
                                    <Image
                                        className="w-full h-full"
                                        src="/images/altGraaf-favicon.svg"
                                    />
                                </div>
                                <div className="   my-4 md:my-2  h-34 border-r"></div>

                                <div className=" w-16 h-16 my-6 md:w-20 md:h-20 2xl:w-28 2xl:h-28 flex justify-center align-center p-3 md:p-4  rounded-lg  ml-10 bg-basicWhite">
                                    <Image className="" src={opportunityLogo} />
                                </div>
                            </div>

                            {/* Text */}
                            <div className="text-center lg:px-0">
                                <h1 className="text-basicWhite text-3xl md:text-4xl mb-2 font-semibold">
                                    {opportunityName}
                                </h1>
                                <h4 className="text-basicWhite text-lg md:py-4 md:text-xl font-medium md:font-normal">
                                    {opportunityTitle}
                                </h4>
                            </div>
                        </div>
                    </div>

                    {/* Arrow */}
                    <div className="hidden lg:flex  lg:justify-center lg:align-center lg:-mr-10  lg:-ml-16 lg:pb-40 xl:pb-28  lg:px-10">
                        <Image src="/images/signup_arrow.svg" />
                    </div>

                    {/* Sign Up Section */}
                    <div className="relative flex w-full md:w-1/2 flex-col justify-center px-4 py-10 md:pb-20 md:pt-16 items-center lg:4/5">
                        <div className="flex flex-col items-center  lg:4/5 justify-center">
                            {/* Sign Up Form */}

                            <div
                                ref={ref}
                                className="z-100 w-full relative justify-items-center self-center bg-basicWhite rounded-lg mx-5 sm:mx-5 sm:px-10 px-5 md:mx-10 lg:mx-30  pt-10"
                            >
                                {/* Dots SVG Left Bottom */}
                                <div className="absolute -bottom-10 -left-10 z-10 lg:block hidden">
                                    <Image src="/images/25_dots.svg" alt="" />
                                </div>

                                {/*Dots SVG Right Bottom  */}
                                <div className="absolute -top-10 -right-10 z-10 lg:block hidden">
                                    <Image src="/images/25_dots.svg" alt="" />
                                </div>

                                {/* Brand Logo */}
                                <div className="hidden  md:block md:mb-8 ">
                                    <Image
                                        className=""
                                        alt="Brand logo"
                                        src="/images/logo.svg"
                                    />
                                </div>

                                <h2 className=" text-2xl md:text-3xl font-normal mb-6 md:mb-10  text-gray-900 font-heading ">
                                    {showEmailInput ? 'Sign Up' : 'Login'} to
                                    view the
                                    <span className="font-semibold">
                                        {' '}
                                        {opportunityName}{' '}
                                    </span>
                                    opportunity!
                                </h2>

                                <LoginModalData
                                    isStatic={true}
                                    className="w-full"
                                    showEmailInput={showEmailInput}
                                    userInput={userInput}
                                    setUserInput={(value) =>
                                        setUserInput(value)
                                    }
                                    setShowVerifyOtpModal={(value) =>
                                        setShowVerifyOtpModal(value)
                                    }
                                    setShowEmailInput={(value) =>
                                        setShowEmailInput(value)
                                    }
                                    showSignUp={showsignup}
                                    setShowSignUp={setshowsignUp}
                                    setShowLogin={setShowLogin}
                                    loading={loading}
                                    setLoading={setLoading}
                                    onClose={onLoginModalClose}
                                    fromOpportunitySignup={true}
                                    setMultipleLogin={setMultipleLogin}
                                    mobileRef={mobileRef}
                                    emailRef={emailRef}
                                    setOtpModes={setOtpModes}
                                    setModalLoadingState={setIsModalInLoadingState}
                                />

                                {showVerifyOtpModal && !isSessionCreationError ?
                                    showEmailInput
                                        ? (
                                            <VerifyPhoneAndEmailModal
                                                show={showVerifyOtpModal}
                                                title={texts?.VerifyOTP}
                                                mobileContext={otpContextEnum.MOBILE_VERIFICATION}
                                                onClose={onLoginModalClose}
                                                mobileNumber={userInput.mobile}
                                                emailId={userInput.email}
                                                editMobileNumber={editMobileNumber}
                                                editEmailId={editEmail}
                                                countryCode={userInput.countryCode}
                                                onSuccess={onOtpVerificationSuccess}
                                            />)
                                        : (
                                            <div className="mx-20 md:mx-10 w-full">
                                                <VerifyOtpModal
                                                    referralCode={referralCode}
                                                    referralEntity={referralEntity}
                                                    show={showVerifyOtpModal}
                                                    onClose={onLoginModalClose}
                                                    mobileNumber={userInput.mobile}
                                                    email={userInput.email}
                                                    countryCode={userInput.countryCode}
                                                    editMobileNumber={editMobileNumber}
                                                    userInput={userInput}
                                                    showEmailInput={showEmailInput}
                                                    multipleLogin={multipleLogin}
                                                    otpModes={otpModes}
                                                    opportunityId={opportunityId}
                                                />
                                            </div>
                                        ) : null}

                                {isSessionCreationError &&
                                    <MessageModal
                                        isError={isSessionCreationError}
                                        show={isSessionCreationError}
                                        title={texts?.VerifyOTP}
                                        buttonText={texts?.TryAgain}
                                        message={texts?.UnableToSignUp}
                                        onClick={onBack}
                                        onClose={onLoginModalClose}
                                    />}
                            </div>

                            {/* To be handled later */}
                            {/* <div className="absolute text-center bottom-10 flex justify-start w-full">
                                <Text
                                    content={
                                        'Copyright © 2022, altGraaf. All rights reserved.'
                                    }
                                    className="text-xs font-paragraph text-gray-200 md:text-sm "
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithOpportunityPage;
