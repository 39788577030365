import { Image, Text } from '@ui/atoms';
import React, { useEffect, useRef, useState } from 'react';
import texts from '@components/Referral/en.json';
import { signUpData } from './helpers';
import { cn } from '@ui/helpers/utils';
import LazyImage from '@atoms/LazyImage';

const SignUpBanner = ({ showEmailInput }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            ref={containerRef}
            className={'relative flex flex-col flex-1 w-full min-h-0 overflow-hidden md:min-h-full xl:min-h-full'}
        >
            <div
                className="absolute top-0 bottom-0 left-0 right-0 opacity-1 md:hidden"
                tabIndex={-1}
            >
                <Image
                    src="/images/signup/bg-signup-page-mobile.webp"
                    className="w-full h-full relative"
                    loading='lazy'
                />
            </div>
            <div className="flex flex-col overflow-hidden flex-1 w-full max-w-[580px] mx-auto backdrop-blur">
                <div className="flex w-full px-4 py-6 mb-10 md:mb-6 md:py-10 md:px-0">
                    <Image src="/images/logo.svg" className="w-28" />
                </div>
                <Text
                    className="px-4 h3-regular md:inline-block text-primary-500 md:mb-16 md:px-0 w-full"
                    content={texts.InvestIn}
                    isInnerHtml
                />
                <div
                    className={'flex flex-col w-full flex-1'}
                >
                    <div className="w-20 mx-4 my-10 rounded-full border-1 border-secondary-500 md:hidden" />
                    <div className="flex flex-col gap-4 px-4 mb-10 md:gap-6 md:mb-8 md:px-0">
                        {signUpData.map((data, index) => (
                            <div
                                key={index}
                                className={cn(
                                    'flex md:items-center justify-start gap-2 md:gap-6',
                                    data?.className
                                )}
                            >
                                <div className="items-center justify-center hidden w-20 h-20 md:flex">
                                    <LazyImage
                                        src={data.icon}
                                        className="w-20 h-20 relative"
                                    />
                                </div>

                                <Image
                                    src="/images/checked-filled.svg"
                                    className="w-4 h-4 md:hidden my-0.5 md:mt-0"
                                />
                                <div className="flex flex-col flex-1 gap-1">
                                    <Text
                                        className="hidden text-gray-900 p5-regular md:p4-semibold md:inline-block"
                                        content={data.title}
                                        isInnerHtml
                                    />
                                    <Text
                                        className="text-gray-900 p5-regular md:hidden"
                                        content={data.mobileText || data?.title}
                                        isInnerHtml
                                    />
                                    <Text
                                        className="hidden text-gray-600 p5-regular md:inline-block"
                                        content={data.description}
                                        isInnerHtml
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpBanner;
