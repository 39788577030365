import { Image, Text } from '@ui/atoms';
import React from 'react';

const FestiveSeasonDiwaliMobile = ({ texts }) => {
    return (
        <div className="flex flex-col justify-center items-center w-full mt-10 px-4 md:px-8 md:hidden">
            <Image
                src="/images/season-of-prosperity.svg"
                className="w-[109px] h-full"
            />
            <Image
                src="/images/page-line.svg"
                className="my-[10px] w-full h-full"
            />
            <Text
                content={texts?.SopDescription}
                className="text-primary-500 p5-regular text-center font-normal pb-4"
                isInnerHtml
            />
        </div>
    );
};

export default FestiveSeasonDiwaliMobile;
