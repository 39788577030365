import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import {
    TAX_OPTIMISER_QUESTIONNAIRES,
    GET_TAX_OPTIMISER_RESULTS,
    TAX_OPTIMISER_MEETINGS,
    GET_FEATURE_USER,
    UPDATE_TAX_OPTIMISER_RESULTS,
} from '@services/urls/taxOptimiser';
import { hasWindow } from '@services/http.service';

export const createTaxOptimiserQuestionnaire = (body, ctx = null) => {
    const url = TAX_OPTIMISER_QUESTIONNAIRES();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const getTaxOptimiserResult = (entityId, ctx = null) => {
    const url = GET_TAX_OPTIMISER_RESULTS(entityId);
    
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createTaxOptimiserMeeting = (body, ctx = null) => {
    const url = TAX_OPTIMISER_MEETINGS();
    
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const getFeatureUserByFeatureIdAndEntityId = (entityId, featureId, ctx = null) => {
    const url = GET_FEATURE_USER(entityId, featureId);
    
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const updateTaxOptimiserResult = (id, details, ctx = null) => {
    const url = UPDATE_TAX_OPTIMISER_RESULTS(id);
    
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, details);
    }
    return SsrHttpService.putWithAuth(url, details, ctx);
};
