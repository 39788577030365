import { useState, useEffect } from 'react';

function useLocalStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(initialValue);
    const setValue = (value) => {
        setStoredValue(value);
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setStoredValue(getValue(key));
        }
    }, []);

    const getValue = (key) => {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
    }

    return [storedValue, setValue, getValue];
}

export default useLocalStorage;
