import { Image } from '@atoms/index';
import LoginModalData from '@components/Auth/LoginModal/LoginModalData';
import VerifyOtpModal from '@components/Auth/VerifyOtpModal';
import FestiveSeasonDiwaliMobile from '@components/Referral/FestiveSeason/FestiveSeasonDiwaliMobile';
import FestiveSeasonNewYearMobile from '@components/Referral/FestiveSeason/FestiveSeasonNewYearMobile';
import ReferralLoginData from '@components/Referral/ReferralLoginData.js';
import texts from '@components/Referral/en.json';
import useIntersection from 'hooks/useIntersection';
import { useEffect, useRef, useState } from 'react';
import VerifyPhoneAndEmailModal from '@components/Auth/VerifyPhoneAndEmailModal';
import { createSession } from '@services/session.service';
import MessageModal from '@molecules/MessageModal';
import { useRouter } from 'next/router';
import { useInvestorContext } from '@context/InvestorContextProvider';
import { otpContextEnum } from '@ui/helpers/enum';
import { triggerCustomEvent } from '@helpers/miscelleanous';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';
import SignUpBanner from './SignUpBanner';
import { cn } from '@ui/helpers/utils';
import useWindowDimensions from '@ui/hooks/useWindowDimensions';
import {
    logEvent,
    setUserData,
    moengageEventConstants,
    setUserAttribute,
    moengageEventArgsConstants, moengageAttributesConstants
} from "@services/moengage";

const WithoutOpportunityPage = ({
    showEmail,
    referralCode,
    referralEntity,
    login,
    stickyBtnVisible,
    setStickyBtnVisible,
    isFestiveSession,
    festiveVersion,
    isNriVisitor,
    scrollToHashElement,
    isSignUp,
}) => {
    const { width } = useWindowDimensions();
    const [showEmailInput, setShowEmailInput] = useState(showEmail);
    const [switchToSignup, setSwitchToSignup] = useState(false);
    const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);
    const [userInput, setUserInput] = useState({});
    const [loading, setLoading] = useState(false);
    const [multipleLogin, setMultipleLogin] = useState(false);
    const [isSessionCreationError, setIsSessionCreationError] = useState(false);
    const router = useRouter();
    const { setMenus } = useInvestorContext();
    const mobileRef = useRef(null);
    const emailRef = useRef(null);
    const [otpModes, setOtpModes] = useState([]);
    const [isModalInLoadingState, setIsModalInLoadingState] = useState(false);

    const editMobileNumber = () => {
        setShowVerifyOtpModal(false);
        mobileRef.current?.numberInputRef?.focus();
    };

    const editEmail = () => {
        setShowVerifyOtpModal(false);
        emailRef.current?.focus();
    };

    const onBack = () => {
        setIsSessionCreationError(false);
        setShowVerifyOtpModal(false);
    };

    const onLoginModalClose = () => {
        setShowVerifyOtpModal(false);
        setLoading(false);
    };

    const onOtpVerificationSuccess = async ({ otpIdList }) => {
        const gclid = router?.query?.gclid;
        const response = await createSession({
            mobile: userInput.mobile,
            otpIdList,
            referralEntity,
            userDetails: {
                ...userInput,
                countryCode: userInput.countryCodeWithPrefix,
            },
            gclid,
            router,
            setMenus,
        });
        if (response.status) {
            await setUserData({auth: response?.entity});
            logEvent({
                eventName: moengageEventConstants.SIGNUP_SUCCESS,
                parameters: {
                    [moengageEventArgsConstants.INVESTMENT_TIME_FRAME]: userInput?.investmentTimeFrame
                }
            });
            setUserAttribute({
                attributeName: moengageAttributesConstants.WHATSAPP_OPT_IN,
                attributeValue: userInput?.whatsappOptIn,
            });
            triggerCustomEvent(gaEventNameEnum.SIGNUP_VERIFY_OTP, {
                mobile: userInput.mobile,
            });
        }
        setIsSessionCreationError(!response.status);
    };

    const [showSignUp, setShowSignUp] = useState(!login);
    const [showLogin, setShowLogin] = useState(login);
    const ref = useRef();
    const inViewport = useIntersection(ref, '0px');

    useEffect(() => {
        if (!inViewport) {
            setStickyBtnVisible({
                ...stickyBtnVisible,
                display: 'block',
                buttonText: isSignUp ? texts?.SignUpInvest : null,
            });
        } else {
            setStickyBtnVisible({
                ...stickyBtnVisible,
                display: 'hidden',
                buttonText: isSignUp ? texts?.SignUpInvest : null,
            });
        }
    }, [inViewport]);

    const handleLoginToExistingAccount = () => {
        setShowEmailInput(false);
        setStickyBtnVisible({
            display: 'hidden',
            isSignupActive: false,
            buttonText: isSignUp ? texts?.SignUpInvest : null,
        });
        setShowLogin(true);
        setShowSignUp(false);
        setUserInput({});
        isSignUp || isNriVisitor
            ? scrollToHashElement('signUpForm')
            : window.scrollTo(0, 0);
    };

    const handleCreateAccount = () => {
        setShowEmailInput(true);
        setStickyBtnVisible({
            display: 'hidden',
            isSignupActive: true,
            buttonText: isSignUp ? texts?.SignUpInvest : null,
        });
        setShowLogin(false);
        setShowSignUp(true);
        setSwitchToSignup(!switchToSignup);
        setUserInput({});
        isSignUp || isNriVisitor
            ? scrollToHashElement('signUpForm')
            : window.scrollTo(0, 0);
    };

    return (
        <>
            <div
                className={cn(
                    'relative w-full flex md:flex-row md:pb-3 flex-col-reverse overflow-hidden items-start signup-page-wrapper xl:px-5',
                    {
                        'flex-col md:pb-0 xl:pb-0 md:pr-4': isNriVisitor,
                        'flex flex-col md:flex-col xl:flex-row': isSignUp,
                    }
                )}
            >
                {isSignUp && (
                    <div
                        className="absolute top-0 left-0 right-0 hidden opacity-1 md:inline-block"
                        tabIndex={-1}
                    >
                        <Image
                            src="/images/signup/bg-signup-page-web.webp"
                            className="w-full h-full"
                            loading='lazy'
                        />
                    </div>
                )}
                {/* Side Bar With Info */}
                {isSignUp ? (
                    <SignUpBanner showEmailInput={showEmailInput} />
                ) : (
                    <ReferralLoginData
                        isFestiveSession={isFestiveSession}
                        isNriVisitor={isNriVisitor}
                        scrollToHashElement={scrollToHashElement}
                        stickyBtnVisible={stickyBtnVisible}
                    />
                )}

                {/* Sign Up Section */}
                <div
                    className={`bg-center ${isFestiveSession
                        ? `${festiveVersion === 'V1' &&
                        'bg-signup-page-festive-card-mobile-and-tab'
                        } ${festiveVersion === 'V2' &&
                        'bg-signup-festive-bg-mobile'
                        } md:signup-page-bg`
                        : 'signup-page-bg'
                        } bg-no-repeat bg-cover relative flex w-full md:w-[60%] xl:w-[48%] flex-col items-center ${!isFestiveSession ? 'pt-6' : ''
                        } ${isSignUp
                            ? 'xl:pt-16 xl:pb-10 md:w-full md:max-w-[580px] xl:max-w-none md:mx-auto'
                            : ''
                        }`}
                >
                    {!isSignUp && !isNriVisitor ? (
                        <div className="fixed top-0 flex justify-center w-full py-3 bg-basicWhite md:hidden z-100 shadow-custom-xs">
                            <Image src="/images/logo.svg" className="w-28" />
                        </div>
                    ) : null}

                    {!isSignUp && !isFestiveSession ? (
                        <>
                            {!isNriVisitor ? (
                                <div className="inline mx-8 mt-10 mb-5 text-center text-gray-900 h5-regular md:h3-regular md:hidden">
                                    {texts.ExploreTheWorldOf}{' '}
                                    <span className="inline mb-6 text-center h5-semibold md:h3-semibold text-primary-500 md:hidden">
                                        {
                                            texts.AlternativeFixedIncomeInvestments
                                        }
                                    </span>
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <>
                            {festiveVersion === 'V1' ? (
                                <FestiveSeasonDiwaliMobile texts={texts} />
                            ) : null}
                            {festiveVersion === 'V2' ? (
                                <FestiveSeasonNewYearMobile texts={texts} />
                            ) : null}
                        </>
                    )}
                    <div
                        className={`flex flex-col items-center w-11/12 md:w-full xl:w-4/5 xl:pr-0 justify-center md:pb-6 md:py-0 mt-0 xl:mt-4`}
                        id="signUpForm"
                        ref={ref}
                    >
                        {/* Sign Up Form */}

                        <div
                            className={
                                isFestiveSession && festiveVersion === 'V1'
                                    ? 'bg-festive-opportunity-card-border-gradient p-[3px] rounded-[20px] w-full shadow-signup-page-card'
                                    : 'w-full'
                            }
                        >
                            <div
                                className="relative self-center w-full p-4 justify-items-center bg-basicWhite rounded-2xl xl:pt-6 xl:px-8"
                                style={{
                                    boxShadow:
                                        '0px 6px 18px rgba(11, 7, 110, 0.07)',
                                }}
                            >
                                <div className="flex justify-between w-full">
                                    <h1 className="mb-6 h5-semibold xl:h4-semibold md:mb-5 text-primary-500 font-heading ">
                                        {showEmailInput ? 'Sign Up' : 'Login'}
                                    </h1>
                                    {showEmailInput && (
                                        <div className="p6-regular text-gray-600 mt-2 md:mt-2.5">
                                            {texts.AlreadyHaveAnAccount}
                                            <span
                                                className="pl-1 cursor-pointer text-primary-500 p6-underline"
                                                onClick={() =>
                                                    handleLoginToExistingAccount()
                                                }
                                            >
                                                {texts.Login}
                                            </span>
                                        </div>
                                    )}
                                    {!showEmailInput && (
                                        <div className="p6-regular text-gray-600 font-medium mt-2 md:mt-2.5">
                                            {texts.NewToAltGraaf}
                                            <span
                                                className="pl-1 cursor-pointer text-primary-500 p6-underline"
                                                onClick={() => {
                                                    handleCreateAccount();
                                                }}
                                            >
                                                {texts.SignUp}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <LoginModalData
                                    isStatic={true}
                                    className="w-full"
                                    showEmailInput={showEmailInput}
                                    userInput={userInput}
                                    setUserInput={(value) =>
                                        setUserInput(value)
                                    }
                                    setShowVerifyOtpModal={(value) =>
                                        setShowVerifyOtpModal(value)
                                    }
                                    setShowEmailInput={(value) =>
                                        setShowEmailInput(value)
                                    }
                                    showSignUp={showSignUp}
                                    setShowSignUp={setShowSignUp}
                                    setShowLogin={setShowLogin}
                                    loading={loading}
                                    setLoading={setLoading}
                                    onClose={onLoginModalClose}
                                    switchToSignup={switchToSignup}
                                    setMultipleLogin={setMultipleLogin}
                                    mobileRef={mobileRef}
                                    emailRef={emailRef}
                                    setOtpModes={setOtpModes}
                                    isNriVisitor={isNriVisitor}
                                    setModalLoadingState={setIsModalInLoadingState}
                                />

                                {showVerifyOtpModal &&
                                    !isSessionCreationError ? (
                                    showEmailInput ? (
                                        <VerifyPhoneAndEmailModal
                                            show={showVerifyOtpModal}
                                            title={texts?.VerifyOTP}
                                            mobileContext={
                                                otpContextEnum.MOBILE_VERIFICATION
                                            }
                                            onClose={onLoginModalClose}
                                            mobileNumber={userInput.mobile}
                                            emailId={userInput.email}
                                            editMobileNumber={editMobileNumber}
                                            editEmailId={editEmail}
                                            countryCode={userInput.countryCode}
                                            onSuccess={onOtpVerificationSuccess}
                                        />
                                    ) : (
                                        <div className="mx-20 md:mx-10 w-full">
                                            <VerifyOtpModal
                                                referralCode={referralCode}
                                                referralEntity={referralEntity}
                                                show={showVerifyOtpModal}
                                                onClose={onLoginModalClose}
                                                mobileNumber={userInput.mobile}
                                                email={userInput.email}
                                                countryCode={
                                                    userInput.countryCode
                                                }
                                                editMobileNumber={
                                                    editMobileNumber
                                                }
                                                userInput={userInput}
                                                showEmailInput={showEmailInput}
                                                multipleLogin={multipleLogin}
                                                otpModes={otpModes}
                                            />
                                        </div>
                                    )
                                ) : null}

                                {isSessionCreationError && (
                                    <MessageModal
                                        isError={isSessionCreationError}
                                        show={isSessionCreationError}
                                        title={texts?.VerifyOTP}
                                        buttonText={texts?.TryAgain}
                                        message={texts?.UnableToSignUp}
                                        onClick={onBack}
                                        onClose={onLoginModalClose}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {isSignUp && width < 768 && (
                    <>
                        <div className="inline mx-8 mt-12 mb-5 text-center text-gray-900 h5-regular md:h3-regular md:hidden">
                            {texts.ExploreTheWorldOf}{' '}
                            <span className="inline mb-6 text-center h5-semibold md:h3-semibold text-primary-500 md:hidden">
                                {texts.AlternativeFixedIncomeInvestments}
                            </span>
                        </div>
                        <ReferralLoginData
                            isFestiveSession={isFestiveSession}
                            isNriVisitor={isNriVisitor}
                            scrollToHashElement={scrollToHashElement}
                            stickyBtnVisible={stickyBtnVisible}
                            isSignUp={isSignUp}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default WithoutOpportunityPage;
