import { replaceKeysWithValues } from '@helpers/string';
import { Image, Text } from '@ui/atoms';
import React from 'react';

const FestiveSeasonNewYearMobile = ({ texts }) => {
    return (
        <div className="md:hidden mt-10 mb-4 flex flex-col gap-3 items-center">
            <Image
                src="/images/signup/festive-season/sop-new-year-signup.svg"
                className="w-[120px] h-18"
            />
            <div className="flex flex-col gap-3 items-center">
                <Text
                    content={texts?.SopNewYearDescription}
                    className="text-basicWhite h5-semibold max-w-[280px] xs-400:max-w-none text-center"
                />
                <div className="flex flex-col gap-2 items-center">
                    <div className="flex flex-col gap-1 items-center">
                        <Text
                            content={replaceKeysWithValues(
                                texts?.Get1000IdText,
                                {
                                    '{amount}':
                                        '<span class="p4-semibold text-basicWhite"> ₹1000 </span>',
                                }
                            )}
                            className="text-gray-100 p6-regular text-center"
                            isInnerHtml={true}
                        />
                        <Image
                            src="/images/signup/festive-season/sop-new-year-signup-line.svg"
                            className="w-[300px] h-full"
                        />
                        <Text
                            content={replaceKeysWithValues(
                                texts?.Get3000NcdText,
                                {
                                    '{amount}':
                                        '<span class="p4-semibold text-basicWhite"> ₹3000 </span>',
                                }
                            )}
                            className="text-gray-100 p6-regular text-center"
                            isInnerHtml={true}
                        />
                    </div>
                    <Text
                        content={texts?.TCApply}
                        className="text-primary-50 p7-regular text-center"
                    />
                </div>
            </div>
        </div>
    );
};

export default FestiveSeasonNewYearMobile;
