import Text from '@atoms/Text';
import texts from '@components/Referral/en.json';

const FeatureCard = (jiraafFeature, index) => {
    return (
        <div
            className={`px-4 pt-4 pb-6 xl:pb-10 xl:pr-10 xl:pl-6 xl:pt-6 rounded-xl bg-referrral-feature relative ${
                index ? '-mt-4' : 'top-0'
            }`}
            key={jiraafFeature.title}
            {...(jiraafFeature?.dataAosProps || {})}
        >
            <Text
                content={jiraafFeature.title}
                className="p3-semibold text-primary-500 pb-1"
                htmlTag='h3'
            />
            <Text
                content={jiraafFeature.value}
                className={`p5-regular text-gray-900 ${
                    index === 1 ? 'mb-2 xl:mb-0' : ''
                }`}
            />
        </div>
    );
};

const Features = ({
    featuresList,
    bgColor = 'md:bg-referral-gradient-bg-primary',
    primaryText = replaceKeysWithValues(
        texts.WeDoTheHeavyLiftingForYouToMakeInvestmentEasy,
        { '{br}': '' }
    ),
    primaryFonts = 'h5-semibold md:h4-semibold xl:h3-semibold',
    secondaryText = (
        <>
            {texts.GetAccessToCuratedRiskAssessed}
            <br className="md:hidden block" /> {texts.Opportunities}{' '}
            <br className="xl:block hidden" />{' '}
            {texts.DesignedForRetailInvestors}
        </>
    ),
    secondaryFonts = 'p5-regular md:p4-regular',
    breakpoint = 'md',
}) => {
    return (
        <div className="md:px-6 xl:px-16">
            <div
                className={`grid grid-cols-2 gap-8 md:gap-14 md:mt-16 md:px-6 xl:px-12 md:mb-0 items-center ${bgColor} bg-no-repeat bg-cover pt-12 pb-0 md:py-16 mx-4 md:mx-auto rounded-3xl !max-w-7xl`}
            >
                <div
                    className={`w-full col-span-full ${breakpoint}:col-span-1`}
                >
                    <Text
                        content={primaryText}
                        className={`${primaryFonts} text-primary-500 pb-2 text-center ${breakpoint}:text-left`}
                        isInnerHtml={true}
                        htmlTag='h2'
                    />
                    <Text
                        content={secondaryText}
                        className={`${secondaryFonts} text-gray-900 text-center ${breakpoint}:text-left`}
                    />
                </div>
                <div
                    className={`flex flex-col  bg-primary-30 rounded-xl md:bg-transparent col-span-full ${breakpoint}:col-span-1 w-full`}
                >
                    {featuresList.map((jiraafFeature, index) =>
                        FeatureCard(jiraafFeature, index)
                    )}
                </div>
            </div>
        </div>
    );
};

export default Features;
