import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
    getFestivalSignupConfiguration,
    getQueryParamsFromUrl,
} from '@helpers/miscelleanous';
import dynamic from 'next/dynamic';
import { Text, Image } from '@ui/atoms';
import texts from '@components/Referral/en.json';
import Features from './Features';
import {
    invoiceProductInfoForNriSignup,
} from '@components/Referral//helpers';
import { replaceKeysWithValues } from '@helpers/string';
import useHashScroll from 'hooks/useHashScroll';
import { Button } from '@ui/atoms/design-system';
import { cn } from '@ui/helpers/utils';
import WithoutOpportunityPage from './WithoutOpportunityPage';
import FooterV2 from '@molecules/Footer/V2/Footer';
import { setReferralCode, removeReferralCode, setReferralEntityType, removeReferralEntityType } from '@services/identity.service';
import WithOpportunityPage from './WithOpportunityPage';

const Testimonials = dynamic(
    () => import('@components/Homepage/Testimonials'),
    {
        ssr: false, // load in CSR, because of Hydration issue
    }
);
const Investors = dynamic(() => import('@components/Homepage/Investors'));
const Highlights = dynamic(() => import('@components/Referral/Highlights'));
const FestiveSeasonDiwaliDesktop = dynamic(() => import('@components/Referral/FestiveSeason/FestiveSeasonDiwaliDesktop'));
const FestiveSeasonNewYearDesktop = dynamic(() => import('@components/Referral/FestiveSeason/FestiveSeasonNewYearDesktop'));
const SignupStickyView = dynamic(() => import('@components/Referral/SignupStickyView'));
const InvoiceProductBrandInfo = dynamic(() =>
    import(
        '@components/Products/InvoiceDiscounting/InvoiceProductBrandInfo'
    ).then((mod) => mod.InvoiceProductBrandInfo)
);
const StartInvestingStep = dynamic(() =>
    import('@components/Homepage/startInvestingStep')
);

const Referral = ({ showEmail, opportunityDetails, login, isNriVisitor }) => {
    const router = useRouter();
    const isSignUp = !login && !isNriVisitor;

    const { scrollToHashElement } = useHashScroll(
        50,
        isNriVisitor ? 50 : isSignUp ? 50 : 68
    );
    const [referral, setReferral] = useState(null);
    const [referralEntity, setReferralEntity] = useState(null);
    const [stickyBtnVisible, setStickyBtnVisible] = useState({
        display: 'hidden',
        isSignupActive: !login,
    });
    const [festiveSeasonSignup, setFestiveSeasonSignup] = useState(null);

    const featuresList = [
        {
            title: texts?.RiskAssessed,
            value: texts?.RiskAssessedDesc,
        },
        {
            title: isNriVisitor
                ? texts?.EaseOfInvesting
                : texts?.DiversifiedInvestments,
            value: isNriVisitor
                ? texts?.EaseOfInvestingDesc
                : texts?.DiversifiedInvestmentsDesc,
        },
        {
            title: isNriVisitor ? texts?.SecuredDeals : texts?.EaseOfInvesting,
            value: isNriVisitor
                ? texts?.SecuredDealsDesc
                : texts?.EaseOfInvestingDesc,
        },
    ];

    useEffect(() => {
        const referralCode =
            router.query?.referrer ||
            router.query?.usoc ||
            getQueryParamsFromUrl(window.location.search, 'usoc') ||
            getQueryParamsFromUrl(window.location.search, 'referrer') ||
            getQueryParamsFromUrl(window.location.search, 'v2_referrer');
        if (referralCode) {
            if (
                router.query?.usoc ||
                getQueryParamsFromUrl(window.location.search, 'usoc')
            ) {
                //TODO: Use this from cookies
                setReferralEntity('usoc');
                setReferralEntityType('usoc');
            } else {
                //TODO: Use this from cookies
                setReferralEntity('referrer');
                setReferralEntityType('referrer');
            }
            setReferralCode(referralCode);
            setReferral(referralCode);
        } else {
            removeReferralCode();
            removeReferralEntityType();
            setReferral(null);
        }
        (async () => {
            const festiveSeason = await getFestivalSignupConfiguration();
            setFestiveSeasonSignup(festiveSeason);
        })();
    }, []);

    const goToIDPage = () => {
        window.open('/products/invoice-discounting', '_blank');
    };

    const festiveSeasonSignupConfigValues = ['V1', 'V2'];

    const isFestiveSession = festiveSeasonSignupConfigValues.includes(
        festiveSeasonSignup?.value
    );
    const festiveVersion = festiveSeasonSignup?.value;

    return (
        <div>
            <div
                className={cn('pt-8', {
                    'xl:pb-7 md:bg-nri-signup md:pt-10': isNriVisitor,
                    'pt-0 xl:pt-0': isSignUp,
                })}
            >
                {/* Logo Section */}
                {isFestiveSession ? (
                    <div className="hidden md:block md:mb-6 px-6 xl:px-[78px] max-w-[1440px] mx-auto">
                        <Image className="" alt="logo" src="/images/logo.svg" />
                    </div>
                ) : null}

                {isFestiveSession ? (
                    <div className="md:block hidden px-6 xl:px-8 xl:pl-[58px] xl:pr-[68px] my-8 mx-auto max-w-[1440px]">
                        {festiveVersion === 'V1' ? (
                            <FestiveSeasonDiwaliDesktop texts={texts} />
                        ) : null}
                        {festiveVersion === 'V2' ? (
                            <FestiveSeasonNewYearDesktop texts={texts} />
                        ) : null}
                    </div>
                ) : null}

                {opportunityDetails && !isNriVisitor ? (
                    <WithOpportunityPage
                        referralCode={referral}
                        referralEntity={referralEntity}
                        showEmail={showEmail}
                        opportunityDetails={opportunityDetails}
                        login={login}
                        stickyBtnVisible={stickyBtnVisible}
                        setStickyBtnVisible={setStickyBtnVisible}
                    />
                ) : (
                    <WithoutOpportunityPage
                        referralCode={referral}
                        referralEntity={referralEntity}
                        showEmail={showEmail}
                        login={login}
                        stickyBtnVisible={stickyBtnVisible}
                        setStickyBtnVisible={setStickyBtnVisible}
                        isFestiveSession={isFestiveSession}
                        festiveVersion={festiveVersion}
                        isNriVisitor={isNriVisitor}
                        scrollToHashElement={scrollToHashElement}
                        isSignUp={isSignUp}
                    />
                )}
            </div>
            <div className="overflow-hidden homepage-wrapper">
                <div className="mx-6 xl:mx-[72px] 2xl:mx-0 relative">

                    {isNriVisitor ? <Highlights /> : null}
                </div>
                {isNriVisitor ? (
                    <Highlights
                        isHorizontalView={true}
                        headingText={replaceKeysWithValues(
                            texts?.TheOneStopSolutionForEverythingFixedIncome,
                            {
                                '{startingSpan}':
                                    '<span class="h5-regular md:h3-regular">',
                                '{endingSpan}': '<span>',
                            }
                        )}
                        headingClassName="h5-semibold md:h3-semibold text-primary-500 text-center"
                        subHeadingText={null}
                        highlights={texts?.oneStopSolution}
                    />
                ) : null}
                <div className="md:pb-4">
                        <InvoiceProductBrandInfo
                            invoiceProductInfo={invoiceProductInfoForNriSignup}
                            showFaq={false}
                        />
                        {isNriVisitor ? <div className="flex justify-center">
                            <Button
                                onClick={goToIDPage}
                                buttonText={texts.LearnMore}
                                buttonType="link"
                            />
                        </div> : null}
                </div>
                <Features
                    featuresList={featuresList}
                    primaryText={replaceKeysWithValues(
                        texts.WeDoTheHeavyLiftingForYouToMakeInvestmentEasy,
                        { '{br}': isNriVisitor ? '<br>' : '' }
                    )}
                />
                {isNriVisitor ? (
                    <div className="relative md:pt-8">
                        <div className="relative z-10">
                            <StartInvestingStep
                                onButtonClick={() => {
                                    scrollToHashElement('signUpForm');
                                }}
                            />
                        </div>
                        <div className="w-[244px] h-[348px] md:hidden absolute -top-40 right-0">
                            <Image
                                src={'/images/signup/green-shade.svg'}
                                alt={`green-shade`}
                            />
                        </div>
                    </div>
                ) : null}
                <Testimonials
                    backgroundColor="bg-basicWhite"
                    paddingClass={`py-12 ${isNriVisitor ? 'md:py-20' : 'md:py-16'
                        }`}
                />
                <Investors
                    backgroundColor="bg-basicWhite"
                    paddingClass="pb-12 md:pb-20"
                />
                {isNriVisitor ? (
                    <div className="px-6 py-12 flex flex-col gap-4 bg-primary-10 items-center">
                        <Text
                            content={
                                texts.ExploreThePotentialOfInvoiceDiscounting
                            }
                            className="p5-regular md:p4-regular text-basicBlack text-center"
                        />
                        <Button
                            onClick={() => {
                                scrollToHashElement('signUpForm');
                            }}
                            buttonText={texts.GetStartedToday}
                        />
                    </div>
                ) : null}
                <FooterV2 isLightFooter={false} />
            </div>
            <SignupStickyView
                stickyBtnVisible={stickyBtnVisible}
                offset={isNriVisitor ? 10 : isSignUp ? 20 : 68}
                isSignUp={isSignUp}
                isNriVisitor={isNriVisitor}
            />
        </div>
    );
};

export default Referral;
